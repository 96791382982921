@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "antd/dist/antd.css";
@import "react-toastify/dist/ReactToastify.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "src/common/Loading/Loading.css";

/* prettier-ignore */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
  ****************
  * END CSS RESET
  ****************
**/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  /* color: #777777; */
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  box-sizing: border-box;
  overflow-x: hidden;
}

.slick-prev::before,
.slick-next::before {
  color: transparent !important;
}

/* hot-deals-page */

.custom--steps__color
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #bd1300;
  height: calc(100% - 10px);
  margin-top: 5px;
}

.custom--steps__color
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.custom--steps__color
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: transparent;
}

.custom--steps__color .ant-steps-item-title {
  width: 100%;
}

.overFlow-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  max-width: 100%;
}

.overFlow-5-line {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  max-width: 100%;
}

.ft-slick__dots--custom {
  width: 17px;
  height: 17px;
  background: #3b3b3ba3;
}

.ft-slick__dots--custom:hover {
  background: white;
}

.slick__slider--custom .slick-dots {
  bottom: 15px;
  top: auto;
}

@media (max-width: 768px) {
  .slick__slider--custom .slick-dots {
    bottom: 10px;
  }
}

@media (max-width: 460px) {
  .slick__slider--custom .slick-dots {
    bottom: 5px;
  }
}

.slick__slider--custom .slick-dots li:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 768px) {
  .slick__slider--custom .slick-dots li:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 460px) {
  .slick__slider--custom .slick-dots li:not(:last-child) {
    margin-right: 3px;
  }
}

.slick__slider--custom .slick-dots .slick-active div {
  background: white;
}

.custom-pagination ul {
  width: fit-content;
  margin: 20px auto;
}

.custom-pagination .ant-pagination-item-active {
  background: transparent;
  border: none;
}

.custom-pagination .ant-pagination-item-active div {
  color: white !important;
}

.custom-pagination .ant-pagination-item {
  background: black !important;
  border: none;
}

.custom-pagination .ant-pagination-prev {
  display: none;
}

.custom-pagination .ant-pagination-next {
  display: none;
}

.custom-hover:hover {
  box-shadow: 0 3px 10px #e75613;
  transform: translateY(-2px);
}

.custom-hover:active {
  box-shadow: 0 0px 5px #e75613;
  transform: translateY(2px);
}

.custom-hover {
  transition: 300ms;
}

.animation-card-border {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}

.animation-card-border::before,
.animation-card-border::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #bd1300;
}

.animation-card-border > div::before,
.animation-card-border > div::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #bd1300;
}

.animation-card-border:hover::before,
.animation-card-border:hover::after {
  width: 100%;
}

.animation-card-border:hover > div::before,
.animation-card-border:hover > div::after {
  height: 100%;
}

.animation-card-border::after {
  left: 0;
  bottom: 0;
  transition-duration: 0.3s;
}

.animation-card-border > div::after {
  right: 0;
  top: 0;
  transition-duration: 0.3s;
}

.animation-card-border::before {
  right: 0;
  top: 0;
  transition-duration: 0.3s;
}

.animation-card-border > div::before {
  left: 0;
  bottom: 0;
  transition-duration: 0.3s;
}

/* ::-webkit-scrollbar */
.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #eaf1f3;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #eaf1f3;
  border-radius: 100vh;
  border: 3px solid #b9ccd1;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #eaf1f3;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  outline: 0px solid white;
  border-radius: 5px;
}
.ant-modal-wrap::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  outline: 0px solid white;
  border-radius: 5px;
}

/* khi open popup => mat scroll nen width bi giam 8px -> cac elements fixed right += 8px */
.ant-scrolling-effect {
  width: calc(100% - 8px) !important;
}

.ant-scrolling-effect .button-chatting {
  right: 28px;
}

.ant-modal-root {
  overflow-x: hidden;
}

.ant-modal-mask,
.ant-modal-wrap {
  width: calc(100% - 8px) !important;
}

.custom-dots-li li {
  position: relative;
}
.custom-dots-li li::before {
  content: "";
  width: 12px;
  height: 12px;
  background: red;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;
  border-radius: 100%;
}

/** form-disabled */
.form-disabled .ant-input-disabled,
.form-disabled .ant-picker-disabled,
.form-disabled .ant-input-affix-wrapper-disabled,
.form-disabled input[disabled] {
  cursor: auto;
  color: inherit;
  background-color: #ffffff;
  border-color: #d9d9d9;
  background: #ffffff;
}

.remove-step .ant-input-number-handler-wrap { 
  visibility: hidden;
}
.input-number-center input {
  text-align: center;
}